<template>
    <!--Profile-->
    <div class="profile">
        <div class="row">
             <Sidebar/>
            <div class="col-lg-5">
                <div class="profile-edit card shadow-sm p-3">
                    <div class="card-header bg-transparent border-0">
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                                <h3>Profile Edit</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body table-list">
                        <form action="" class="row">
                            <div class="form-group col-lg-12">
                                <img :src="userPhoto"  style="max-width: 150px; max-height: 150px;">
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="Photo">Photo</label>
                                <div class="custom-file">
                                <input type="file" class="custom-file-input" id="customFile" ref="userPhoto" @change="onChangePhoto">
                               <!--  <label class="custom-file-label" for="customFile">Choose file</label> -->
                            </div>
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="Name">Name</label>
                               <input type="text" class="form-control" aria-describedby="name" v-model="userName">
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="Phone">Phone</label>
                                <input type="number" class="form-control"  aria-describedby="emailHelp" v-model="userPhone">
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="Email">Email</label>
                               <input type="email" class="form-control" aria-describedby="emailHelp" v-model="userEmail">
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="dob">Date Of Birth</label>
                                <input type="date" class="form-control"  aria-describedby="emailHelp" v-model="dob">
                            </div>

                            <div class="form-group col-lg-12 text-center">
                                <button type="button" class="btn btn-primary" v-on:click="updateProfile()">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
             <div class="col-lg-4">
                <div class="profile-edit card shadow-sm p-3">
                    <div class="card-header bg-transparent border-0">
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                                <h3>Password Area</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body table-list">
                        <form action="" class="row">
                          
                            <div class="form-group col-lg-12">
                                <label for="name">Current Password:</label>
                                <input type="password" class="form-control" aria-describedby="name" v-model="currentPassword">
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="exampleInputEmail1">New Password: </label>
                                <input type="password" class="form-control" aria-describedby="emailHelp" v-model="newPassword">
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="exampleInputEmail1">Confirm Password: </label>
                                <input type="password" class="form-control"  aria-describedby="emailHelp" v-model="confirmPassword">
                            </div>

                            <div class="form-group col-lg-12 text-center">
                                <button type="button" class="btn bg-warning"  v-on:click="passwordChange()">Change</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
             <slot v-for="(addressInfo,index) in addressList"> 
                <div class="col-lg-4">
                    <div class="profile-edit card shadow-sm p-3">
                        <div class="card-header bg-transparent border-0">
                            <div class="row no-gutters">
                                <div class="col-lg-12">
                                    <h3>Address {{++index}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card-body table-list">
                            <form action="" class="row">
                                 <div class="form-group col-lg-12">
                                    <label for="name">Name:</label>
                                    <input type="text" class="form-control"  aria-describedby="name" :ref="'name_'+addressInfo.id" :value="addressInfo.name" readonly>
                                </div>
                                <div class="form-group col-lg-12">
                                    <label for="name">Phone:</label>
                                    <input type="text" class="form-control" aria-describedby="name" :ref="'phone_'+addressInfo.id" :value="addressInfo.phone" readonly>
                                </div>
                                <div class="form-group row">
                                  <div class="col-6">
                                      <label for="district">District</label>
                                      <select class="form-control" :ref="'district_'+addressInfo.id" readonly>
                                        <option :value="districtInfo.id" v-for="districtInfo in districtList" :selected="districtInfo.id==addressInfo.upazila_info.district">{{districtInfo.name}}</option>
                                      </select>
                                  </div>
                                  <div class="col-6 ">
                                     <label for="district">Upazila</label>
                                     <select class="form-control" :ref="'upazila_'+addressInfo.id" readonly>
                                        <option :value="upazilaInfo.id" v-for="upazilaInfo in upazilaList" :selected="upazilaInfo.id==addressInfo.upazila_info.district" >{{upazilaInfo.name}}</option>
                                      </select> 
                                  </div>
                                </div>
                                <div class="form-group col-lg-12">
                                    <label for="name">Address:</label>
                                    <textarea class="form-control" aria-describedby="name" :ref="'address_'+addressInfo.id" placeholder="Enter Address Here" :value="addressInfo.address" rows="4" readonly></textarea>
                                </div>
                                <div class="form-group col-lg-12">
                                    <!-- <button class="btn text-light" :class="(addressInfo.status==1) ? 'btn-success':'btn-warning'" @click="addressStatusChange(addressInfo.id)">
                                      <i class="fa " :class="(addressInfo.status==1) ? 'fa-check':'fa-times'"></i>
                                    </button> -->
                                    <!-- <button class="btn btn-danger text-light" @click="deleteAddress(addressInfo.id)">
                                      <i class="fa fa-trash"></i>
                                    </button> -->
                                   <!--  <a  class="btn btn-info text-light px-4 py-2" @click="updateAddress(addressInfo.id)">
                                      <i class="fa fa-upload"></i>
                                      Update
                                     </a> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </div>
    <!--/Profile-->
</template>

<script>
import moment from 'moment';
import Sidebar from './Sidebar';
export default{
    components: {
        Sidebar,
    },
    data(){
        return {
            customerInfo:this.$store.state.customerInfo,
            customerPhone:this.$store.state.customerPhone,
            token:this.$store.state.apiToken,
            remember:this.$store.state.rememberToken,
            errMsgFlag:false,
            msgFlag:false,
            msg:null,
            errMsg:null,
            userName:null,
            userPhone:null,
            userEmail:null,
            userPhoto:null,
            dob:null,
            addressList:null,
            upazilaList:null,
            districtList:null,
            currentPassword:null,
            newPassword:null,
            confirmPassword:null,
            cusName:null,
            cusPhone:null,
            cusDistrict:null,
            cusUpazila:null,
            cusAddress:null,
        }
    },
    mounted(){
        this.loadCustomerInfo();
        this.getAreaList();
        this.loadCustomerAddress();
    },
    watch:{
        $route (to, from){ 
            this.loadCustomerInfo();
            this.getAreaList();
            this.loadCustomerAddress();
         }
    },
    methods:{
       phoneNumberValidation(phone)
       {
          let pattern=/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
          let flag=pattern.test(phone.trim());
          if(flag)
            return true;
          else
            return false;
       },
      addressFormValidation(){
        if(this.cusName!=null && this.cusPhone!=null && this.cusAddress!=null && this.cusUpazila!=null){
          if(this.phoneNumberValidation(this.cusPhone)){
            return true;
          }
          else{
              this.$toaster.warning("Please Enter Valid Bangladeshi Phone Number.");
              return false;
          }
        }
        else{
          if(this.cusName==null)
            this.$toaster.warning("Please Enter Name.");
          if(this.cusPhone==null)
            this.$toaster.warning("Please Enter Phone No.");
          if(this.cusUpazila==null)
            this.$toaster.warning("Please Select A Upazila First.");
          if(this.cusAddress==null)
            this.$toaster.warning("Please Enter Address.");
        }
      },
      resetAddForm(){
          this.cusName=null;
          this.cusPhone=null;
          this.cusDistrict=null;
          this.cusUpazila=null;
          this.cusAddress=null;
      },
      addAddress(){
        this.errMsgFlag=false;
        this.msgFlag=false;
        if(this.addressFormValidation()){
            let formData=new FormData();
            formData.append("name",this.cusName);
            formData.append("phone",this.cusPhone);
            formData.append("address",this.cusAddress);
            formData.append("upazila",this.cusUpazila);
            formData.append("district",this.cusDistrict);
         
            this.$http.post("add/customer/address",formData)
              .then(response=>{
                  this.errMsgFlag=response.data.errMsgFlag;
                  this.msgFlag=response.data.msgFlag;
                  this.errMsg=response.data.errMsg;
                  this.msg=response.data.msg;
                  if(this.errMsgFlag==true)
                    this.$toaster.warning(this.errMsg);
                  if(this.msgFlag==true){
                    this.$toaster.success(this.msg);
                    this.resetAddForm();
                  }
                  this.loadCustomerAddress();
              })
              .catch(error=>{console.dir(error)})
              .finally();
          }
      },
      formSubmitPrevent(e){
         e.preventDefault();
      },
      deleteAddress(addressId){
        this.errMsgFlag=false;
        this.msgFlag=false;
        this.$http.post("customer/address/delete",{params:{dataId:addressId}})
          .then(response=>{
              this.errMsgFlag=response.data.errMsgFlag;
              this.msgFlag=response.data.msgFlag;
              this.errMsg=response.data.errMsg;
              this.msg=response.data.msg;
              if(this.errMsgFlag==true)
                this.$toaster.warning(this.errMsg);
              if(this.msgFlag==true)
                this.$toaster.success(this.msg);
              this.loadCustomerAddress();
          })
          .catch(error=>{console.dir(error)})
          .finally();
      },
      addressStatusChange(addressId){
        this.errMsgFlag=false;
        this.msgFlag=false;
        this.$http.post("customer/address/status/change",{params:{dataId:addressId}})
          .then(response=>{
            // console.dir(response.data);
              this.errMsgFlag=response.data.errMsgFlag;
              this.msgFlag=response.data.msgFlag;
              this.errMsg=response.data.errMsg;
              this.msg=response.data.msg;
              if(this.errMsgFlag==true)
                this.$toaster.warning(this.errMsg);
              if(this.msgFlag==true)
                this.$toaster.success(this.msg);
              this.loadCustomerAddress();
          })
          .catch(error=>{console.dir(error)})
          .finally();
      },
      updateAddress(addressId){
        this.errMsgFlag=false;
        this.msgFlag=false;
          
          let upazila='upazila_'+addressId;
          let district='district_'+addressId;
          let name='name_'+addressId;
          let phone='phone_'+addressId;
          let address='address_'+addressId;
          let formData=new FormData();
          formData.append("dataId",addressId);
          formData.append("name",this.$refs[name][0].value);
          formData.append("phone",this.$refs[phone][0].value);
          formData.append("address",this.$refs[address][0].value);
          formData.append("upazila",this.$refs[upazila][0].value);
          formData.append("district",this.$refs[district][0].value);
          this.$http.post("update/customer/address/",formData)
           .then(response=>{
            this.errMsg=response.data.errMsg;
            this.msg=response.data.msg;
            this.errMsgFlag=response.data.errMsgFlag;
            this.msgFlag=response.data.msgFlag;
            if(this.errMsgFlag==true)
              this.$toaster.warning(this.errMsg);
            if(this.msgFlag==true)
              this.$toaster.success(this.msg);
            this.loadCustomerAddress();
           })
           .catch(error=>{console.dir(error)})
           .finally();
      
      },
      profileUpdateFormValidation(){
        this.errMsgFlag=false;
        this.msgFlag=false;
        if(this.userName!=null && this.userEmail!=null && this.userPhone )
        {
          return true;
        }
        else{
          if(this.userName==null){
            this.errMsg="Please Enter Your Name.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.userEmail==null){
            this.errMsg="Please Enter Email Address.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.userPhone){
            this.errMsg="Please Enter Your Phone No.";
            this.$toaster.warning(this.errMsg);
          }
          this.errMsgFlag=true;
          return false;
        }
      },
      updateProfile(){
        this.errMsgFlag=false;
        this.msgFlag=false;
        if(this.profileUpdateFormValidation()){

            if(this.$store.state.customerInfo!=null){
                 
                 let config = {
                       headers: {
                          'Content-Type': 'multipart/form-data'
                      }   
                  }; 
                
                let formData=new FormData();
                formData.append("customerPhone",this.customerPhone);
                formData.append("remember",this.remember);
                formData.append("token",this.token);
                formData.append("name",this.userName);
                formData.append("email",this.userEmail);
                formData.append("phone",this.userPhone);
                if(this.dob!=null)
                  formData.append("dob",this.dob);
                
                if(this.userPhoto!=null)
                  formData.append("photo",this.$refs.userPhoto.files[0]);
                this.$http.post("profile/update",formData,config)
                 .then(response=>{
                    this.errMsg=response.data.errMsg;
                    this.msg=response.data.msg;
                    this.errMsgFlag=response.data.errMsgFlag;
                    this.msgFlag=response.data.msgFlag;
                    if(this.errMsgFlag==true)
                      this.$toaster.warning(this.errMsg);
                    if(this.msgFlag==true)
                      this.$toaster.success(this.msg);
                    this.$store.commit("storeCustomerInfo",response.data.customerInfo);
                    this.loadCustomerInfo();
                 })
                 .catch(error=>{console.dir(error)})
                 .finally()
                
            }
            else{
                this.$router.push({name:'Home'});
            }
        }
      },
      passwordFormValidation(){
        this.errMsgFlag=false;
        this.msgFlag=false;
        if(this.currentPassword!=null && this.newPassword!=null && this.confirmPassword!=null){
          if(this.newPassword==this.confirmPassword){
            return true;
          }
          else{
            this.errMsg="New Password Doesn't Confirmed.Please Confirm New Password.";
            this.$toaster.warning(this.errMsg);
            this.errMsgFlag=true;
            return false;
          }
        }
        else{
          if(this.currentPassword==null){
            this.errMsg="Please Enter Current Password.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.newPassword==null){
            this.errMsg="Please Enter New Password.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.confirmPassword==null){
            this.errMsg="Please Confirm Password.";
            this.$toaster.warning(this.errMsg);
          }
          this.errMsgFlag=true;
          return false;
        }
      },
      passwordChange(){
        this.errMsgFlag=false;
        this.msgFlag=false;
     
            if(this.passwordFormValidation()){
              let formData=new FormData();
              formData.append("phone",this.customerPhone);
              formData.append("remember",this.remember);
              formData.append("token",this.token);

              formData.append("currentPassword",this.currentPassword);
              formData.append("newPassword",this.newPassword);
              formData.append("confirmPassword",this.confirmPassword);
              
              
              this.$http.post("password/change",formData)
               .then(response=>{
                  this.errMsgFlag=response.data.errMsgFlag;
                  this.msgFlag=response.data.msgFlag;
                  this.msg=response.data.msg;
                  this.errMsg=response.data.errMsg;
                  if(this.errMsgFlag==true)
                    this.$toaster.warning(this.errMsg);
                  if(this.msgFlag==true)
                    this.$toaster.success(this.msg);
               })
               .catch(error=>{console.dir(error)})
               .finally();
            }
         
      },
      getAreaList(){
        this.$http.get("all/area/list")
         .then(response=>{
            if(response.data.msgFlag==true){
              this.upazilaList=response.data.upazilaList; 
              this.districtList=response.data.districtList; 
            }
         })
         .catch(error=>{console.dir(error)})
         .finally();
      },
      loadCustomerAddress(){
        // console.dir(this.customerInfo);
        if(this.customerInfo!=null){
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);
             // console.dir(formData);

            this.$http.post("customer/address",formData)
             .then(response=>{
                if(response.data.msgFlag==true)
                  this.addressList=response.data.dataList;
             })
             .catch(error=>{console.dir()})
             .finally();
         }
        
      },
      loadCustomerInfo(){
        // console.dir(this.customerPhone);
         if(this.customerInfo!=null){
            let customerInfo=this.$store.state.customerInfo;
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);

            this.$http.post("auth/check",formData)
            .then(response=>{
              if(response.data.msgFlag==true){
                   this.$store.commit("storeCustomerInfo",response.data.customerInfo);
                   if(response.data.customerInfo!=null){
                        this.userName=response.data.customerInfo.name;
                        this.userEmail=response.data.customerInfo.email;
                        this.userEmail=response.data.customerInfo.email;
                        this.userPhone=response.data.customerInfo.phone;
                        this.dob=this.getDateFormat(response.data.customerInfo.dob);
                        this.userPhoto=response.data.customerInfo.avatar;
                   }
              }
              else{
                // this.$store.commit("storeCustomerInfo",null);
                this.$router.push({name:'Home'});
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
        }
        else{
           this.$router.push({name:'Home'});
        }
         // if(this.$store.state.customerInfo!=null){
         //    let customerInfo=this.$store.state.customerInfo;
         //    let formData=new FormData();
         //    formData.append("phone",this.customerPhone);
         //    formData.append("token",this.token);
         //    formData.append("remember",this.remember);

         //    this.$http.post("auth/check")
         //     .then(response=>{
         //        if(response.data.customerInfo!=null){
         //            this.userName=response.data.customerInfo.name;
         //            this.userEmail=response.data.customerInfo.email;
         //            this.userEmail=response.data.customerInfo.email;
         //            this.userPhone=response.data.customerInfo.phone;
         //            this.dob=this.getDateFormat(response.data.customerInfo.dob);
         //            this.userPhoto=response.data.customerInfo.avatar;
         //        }
         //     }).
         //     catch(error=>{console.dir(error)})
         //     .finally();
         // }
         // else{
         //    this.$router.push({name:'Home'});
         // }
      },
       onChangePhoto(e){
            this.errMsgFlag=false;
            this.msgFlag=false;
            const file= e.target.files[0];
            let Extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
            if ( Extension == "png" || Extension == "bmp" || Extension == "jpeg" || Extension == "jpg") {
                this.userPhoto = URL.createObjectURL(file);
                
            }
            else
            {
                this.msg="Photo only allows file types of  PNG, JPG, JPEG and BMP.";
                this.errMsgFlag=true;
                this.msgFlag=false;
            }
       },
       getDateFormat(value){
        if (value) {
          return moment(String(value)).format("Y-MM-DD");
       }
      },
    }
}
</script>